import React from 'react'
import GraphicTemplate from '../../components/pages/graphicTemplate'
import { graphql } from 'gatsby'

const GraphicSk = ({ data, ...otherProps }) => {
  return <GraphicTemplate data={data} lang={'sk'} {...otherProps}/>
}

export const query = graphql`{
    
    common: file(relativePath: {eq: "pages/sk/references/graphic.md"}) {
        childMarkdownRemark {
            frontmatter {
                description
            }
        }
    }
    logoBranding: file(relativePath: {eq: "pages/sk/references/graphic_logo_branding.md"}) {
        childMarkdownRemark {
            frontmatter {
                case_studies {
                    title
                    description
                    thumbnail_layers {
                        thumbnail {
                            childImageSharp {
                                fluid(maxWidth: 1000) {
                                    ...GatsbyImageSharpFluid_withWebp
                                }
                            }
                        }
                    }
                    background_color
                    case_study
                }
                other_projects {
                    title
                    url_override
                    show_in_references_slider
                    url
                    thumbnail {
                        childImageSharp {
                            fluid(maxWidth: 700, quality: 75) {
                                ...GatsbyImageSharpFluid_withWebp
                            }
                        }
                    }
                    case_study
                }
            }
        }
    }
    digitalPrint: file(relativePath: {eq: "pages/sk/references/graphic_digital_print.md"}) {
        childMarkdownRemark {
            frontmatter {
                case_studies {
                    title
                    description
                    thumbnail_layers {
                        thumbnail {
                            childImageSharp {
                                fluid(maxWidth: 1000) {
                                    ...GatsbyImageSharpFluid_withWebp
                                }
                            }
                        }
                    }
                    background_color
                    case_study
                }
                other_projects {
                    title
                    url_override
                    show_in_references_slider
                    url
                    thumbnail {
                        childImageSharp {
                            fluid(maxWidth: 700, quality: 75) {
                                ...GatsbyImageSharpFluid_withWebp
                            }
                        }
                    }
                    case_study
                }
            }
        }
    }
}`

export default GraphicSk

