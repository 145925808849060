//import { navigate } from 'gatsby'
import { getCategoryDisplayName, getCategorySlug, isSafari, slugFormat } from '../../utilities'
import React, { useState } from 'react'
import * as PropTypes from 'prop-types'
import { TranslatedLink } from './../TranslatedLink'
//import AliceCarousel from 'react-alice-carousel'
import AliceCarousel from '../../lib/react-alice-carousel/react-alice-carousel'
import '../../lib/react-alice-carousel/scss/alice-carousel.scss'
import Img from 'gatsby-image'
//import { shouldNavigateBack } from '../templates/case-study'

import CancelDragClick from './../cancelDragClick'
import Tooltip from 'react-tooltip-lite'

export const carouselPositionCache = {}

const ControlButtons = ({ onNext, onPrev }) => {
  const [isNextButtonClicked, setIsNextButtonClicked] = useState(false)


  return (<div className={'buttons'}>
    <button onClick={() => onPrev()}>
      <div className={`slider-prev ${isNextButtonClicked ? 'show' : ''}`}>
        <svg viewBox="0 0 44 44" className="circle-animation">
          <circle className="back" cx="-22" cy="22" r="22" transform="rotate(-90)"></circle>
          <circle className="outer" cx="-22" cy="22" r="22" transform="rotate(-90)"></circle>
        </svg>
      </div>
    </button>

    <button onClick={() => {
      onNext();
      setTimeout(() => setIsNextButtonClicked(true), 0);
    }}>
      <div className="slider-next">
        <svg viewBox="0 0 44 44" className="circle-animation">
          <circle className="back" cx="-22" cy="22" r="22" transform="rotate(-90)"></circle>
          <circle className="outer" cx="-22" cy="22" r="22" transform="rotate(-90)"></circle>
        </svg>
      </div>
    </button>
  </div>)
}

class caseStudiesOtherProjects extends React.Component {
  mouseDownPos = { x: 0, y: 0 }
  state = {
    mounted: false,
  }

  componentDidMount() {
    this.setState({ mounted: true })
  }

  render() {
    const { projects, category, lang, responsive } = this.props
    let interval;
    let sleepDragEnd
    if (projects == null || category == null) {
      return (null)
    }
      
    const handleOnDragStart = (e) => {
      clearTimeout(sleepDragEnd)
      clearInterval(interval)
      interval = ''
      document.querySelectorAll('.other-products-slider').forEach((e) => {
        e.classList.add('is-dragged')
      })
      this.mouseDownPos = {
        x: e.clientX,
        y: e.clientY,
      }
    }

    const handleOnDragEnd = (e) => {
      document.querySelectorAll('.other-products-slider.is-dragged').forEach((e) => {
        e.classList.remove('is-dragged')
      })
    }

    const sliderChange = (e) => {
      carouselPositionCache[category] = e.item
    }

    
    return (
     
      <div className={`other-products mt-5 mt-md-0`}>
        {this.props.enableTitle &&
          <h2>{this.props.title || 'Ďalšie projekty'}</h2>
        }
      
        <div style={{maxWidth: '100vw'}} id="other-products-slider" className={`other-products-slider ${isSafari() ? 'safari' : 'not-safari'}`}>
          <div className={'mask-left'} />
          <AliceCarousel
            mouseTracking
            disableDotsControls={true}
            disableButtonsControls={true}
            responsive={responsive}
            ref={(el) => (this.Carousel = el)}
            onSlideChanged={(e) => {sliderChange(e)}}
            activeIndex={carouselPositionCache[category]}
            autoPlay={false}
            /* autoWidth={true} */
            autoPlayInterval={15000}
            items={projects.map((project, i) => {

              let slug = `${lang === 'en' ? '/en/our-works/' : '/nase-prace/'}${getCategorySlug(category, lang)}/${slugFormat(project.case_study)}`;
              if (project.url && project.url.trim().length > 0) {
                slug = project.url; // TODO: Translations
              }else if (project.url_override && project.url_override.trim().length > 0) {
                slug = project.url_override; // TODO: Translations
              }
               
              return (
                <CancelDragClick
                  key={i + ' caseStudiesOtherProjects-slider'}
                >
                  <div className="item"
                    onMouseDown={handleOnDragStart}
                    onMouseUp={handleOnDragEnd}>
                    <TranslatedLink to={slug}>
                      <div>
                        <div className="img-wrapper" style={{ pointerEvents: 'none' }}>
                          <Img
                            className={'img'}
                            style={{ width: "100%"}}
                            fluid={project.thumbnail.childImageSharp.fluid}
                            alt={project.title} />
                        </div>

                        <span className="projectTitle">{project.title}</span>
                        <span className="tag">
                          <img className="image" src="/images/svg/icon_tag.svg"
                            alt={getCategoryDisplayName(project.category != null ? project.category : category, lang)}
                          />{getCategoryDisplayName(project.category != null ? project.category : category, lang)}</span>

                      </div>
                    </TranslatedLink>
                  </div>
                </CancelDragClick>
              )
            })}>
          </AliceCarousel>
          <div className="slider-nav">
            <Tooltip tagName={'span'} className="side-nav-tooltip" content={'Posúvať ukážky viete aj potiahnutím myšou'}
              direction="down" arrow={false} distance={20}>
              <img className="image" src="/images/svg/icon-dragAnddrop.svg"
                alt={'drag&drop'} />
            </Tooltip>
            <img className="image" src="/images/svg/icon-dragAnddrop.svg"
              alt={'drag&drop'} title={'Posúvať ukážky viete aj potiahnutím myšou'}
            />
            {this.state.mounted &&
              <ControlButtons onNext={this.Carousel.slideNext} onPrev={this.Carousel.slidePrev} />
            }

          </div>
        </div>
      </div>
    )
  }
}

caseStudiesOtherProjects.propTypes = {
  title: PropTypes.string,
  responsive: PropTypes.any,
  projects: PropTypes.any.isRequired,
  category: PropTypes.string.isRequired,
  lang: PropTypes.string.isRequired,
  enableTitle: PropTypes.bool
}

caseStudiesOtherProjects.defaultProps = {
  enableTitle: true,
}
export default caseStudiesOtherProjects
