import React, { Component } from 'react'
import * as PropTypes from 'prop-types'
import { navigate } from 'gatsby'

class CancelDragClick extends Component {
  constructor(props) {
    super(props)
    this.mouseDownPos = {
      x: null,
      y: null,
    }

    this.onMouseDown = this.onMouseDown.bind(this)
    this.onMouseUp = this.onMouseUp.bind(this)
  }

  onMouseDown(e) {
    this.mouseDownPos = {
      x: e.clientX,
      y: e.clientY,
    }
  }

  onMouseUp(e) {
    const dif = Math.abs(this.mouseDownPos.x - e.clientX)
    if (dif <= 1) {
      const el = e.target.querySelector('a')
      if (el != null) {
        const href = el.getAttribute('href')
        if (href != null)
          navigate(href)
      }
    } else {
      e.preventDefault()
    }
  }

  render() {
    const { children, threshold, ...otherProps } = this.props
    return (
      <div
        onMouseDown={this.onMouseDown}
        onMouseUp={this.onMouseUp}
        onClick={e => e.preventDefault()}
        onClickCapture={this.onMouseUp}
        {...otherProps}>
        <div style={{ cursor: 'pointer' }}>
          {children}
        </div>
      </div>
    )
  }
}

CancelDragClick.propTypes = {
  threshold: PropTypes.number,
}

CancelDragClick.defaultProp = {
  threshold: 1,
}
export default CancelDragClick