import React, { Component } from 'react'
import { getCategoryDisplayName, getTranslateFunction } from '../../utilities'
import CaseStudiesOtherProjects from './caseStudiesOtherProjects'
import * as PropTypes from 'prop-types'
import { getSlug } from '../../getSlug'
import VisibilitySensor from 'react-visibility-sensor'
import Img from 'gatsby-image'
import Reveal from './../reveal'
import MultiLayerTilt from './../MultiLayerTilt'
import { Link } from 'gatsby'
import LinkBtn from './../linkBtn/LinkBtn'

class CaseStudiesCategories extends Component {
  constructor(props) {
    super(props)
    this.visibleElementsStack = []
  }


  render() {
    let { categories, scrollHandler, noOtherProjects, lang } = this.props
    return (
      categories.map((category, i) => {
        const categoryName = category.categoryName === 'corporate' ? 'firemne' : category.categoryName
        const caseStudies = category.case_studies
        const otherPorjects = category.other_projects.filter(project => project.show_in_references_slider)

        let ScrollTriggerEl = VisibilitySensor
        if (typeof window === 'undefined') {
          ScrollTriggerEl = (props) => (<div {...props}>{props.children}</div>)
        }

        const t = getTranslateFunction(lang)

        return (
          <ScrollTriggerEl
            key={categoryName}
            partialVisibility={true}
            minTopValue={500}
            onChange={(val) => {
              if (val) {
                this.visibleElementsStack = [...this.visibleElementsStack, categoryName]
              } else {
                this.visibleElementsStack = this.visibleElementsStack.filter(e => e !== categoryName)
              }
              scrollHandler(this.visibleElementsStack[this.visibleElementsStack.length - 1])
            }}
          >
            <div className="references-group cs" id={`${categoryName}`}>
              {noOtherProjects || categoryName === 'app'
                ? null
                : <h2 className={`rg-title ${caseStudies.length === 0 ? 'no-cs' : ''}`}>
                  <span onDoubleClick={() => {
                    try {
                      window.location.hash = categoryName;
                      navigator.clipboard.writeText(window.location.href);
                    } catch (e) {
                      console.log('Copying of scroll URL failed!', e);
                    }
                  }}>{getCategoryDisplayName(categoryName, lang)}</span>
                </h2>}
              {caseStudies.map((caseStudy) => {

                const bgColor = caseStudy.background_color
                const background = `linear-gradient(105deg, transparent 0%, transparent 60%, ${bgColor} 60%, ${bgColor} 100%)`
                return (
                  <Reveal key={caseStudy.title} placeholderHeight={570}>
                    <div className="row references-block pt-5 pt-md-0 align-items-center" id={`${getSlug(caseStudy.title)}`}>
                      <div className="col-md-6 left">
                        <Link to={caseStudy.case_study}>
                          <h3>{caseStudy.title}</h3>
                        </Link>
                        <h4>{getCategoryDisplayName(categoryName, lang)}</h4>
                        <p>{caseStudy.description}</p>
                        <LinkBtn className={'d-none d-md-flex'}>
                          <Link to={caseStudy.case_study}>{t('References.OpenCaseStudyBtn')}</Link>
                        </LinkBtn>
                      </div>
                      <div className="col-md-6 right">
                        <Link to={caseStudy.case_study}>
                          <MultiLayerTilt layers={caseStudy?.thumbnail_layers.map((img, i) => (
                            <Img key={i} fluid={img.thumbnail?.childImageSharp.fluid} alt={caseStudy.title} />
                          ))} />
                        </Link>
                        <LinkBtn className={'d-flex d-md-none justify-content-center w-100'}>
                          <Link to={caseStudy.case_study}>{t('References.OpenCaseStudyBtn')}</Link>
                        </LinkBtn>
                      </div>
                    </div>
                    <div className={'background-element'} style={{ background: background }} />
                  </Reveal>
                )
              })}
              {noOtherProjects ? null :
                <Reveal id={`${getSlug(categoryName)}-other-projects`} placeholderHeight={553}>
                  <CaseStudiesOtherProjects
                    title={'Ďalšie ' + getCategoryDisplayName(categoryName, 'sk', 'Inflected_2')}
                    responsive={
                      {
                        0: { items: 2.5 },
                        991: { items: 2 },
                        1555: { items: 3 },
                        2500: { items: 3.2 },
                      }
                    }
                    projects={otherPorjects}
                    category={categoryName}
                    lang={lang} />
                </Reveal>
              }
            </div>
          </ScrollTriggerEl>
        )
      })

    )
  }
}

CaseStudiesCategories.propTypes = {
  categories: PropTypes.any.isRequired,
  scrollHandler: PropTypes.func.isRequired,
  noOtherProjects: PropTypes.bool,
  lang: PropTypes.string.isRequired,
}

CaseStudiesCategories.defaultProps = {
  noOtherProjects: false,
}
export default CaseStudiesCategories

