import React, { Component } from 'react'
import * as PropTypes from 'prop-types'

class caseStudiesSideMenu extends Component {
  constructor(props) {
    super(props)
    this.state = {
      offset: 469,
    }
    this.listElement = React.createRef()
    this.getOffset = this.getOffset.bind(this)
    this.onScroll = this.onScroll.bind(this)
    this.updateGhostElement = this.updateGhostElement.bind(this)
    this.updateDimensions = this.updateDimensions.bind(this)
    this.lastOffset = null
  }

  onScroll() {
    const offset = this.getOffset()
    this.setState({ offset: offset })
    clearInterval(this.interval)
  }

  updateGhostElement() {
    const { isGhost } = this.props

    if (!isGhost && typeof document !== 'undefined') {
      const offset = this.getOffset()
      const el = document.getElementById('sideMenuWrapper')
  
   
      if (offset === this.lastOffset && (el != null && offset === el.style.transform === `translateY(${Math.round(offset)}px)`)) {
        return
      }
      /*
      const pageTransitionEl = document.getElementById('page-transition-wrapper')
      if (pageTransitionEl == null) {
        return
      }
      const isPageInTransition = !pageTransitionEl.classList.contains('page-transition-status-entered')
      */
      if (el != null && offset !== 0) {
        this.lastOffset = offset
        el.style.transform = `translateY(${Math.round(offset)}px)`
      }
    }
  }


  getOffset() {
    if (this.listElement.current == null) {
      return 469
    }
    return this.listElement.current.getBoundingClientRect().y
  }

  updateDimensions() {
    this.updateGhostElement()
  }

  componentDidMount() {
      
    const { isGhost } = this.props

    if (!isGhost) {
      document.addEventListener('scroll', this.onScroll, {passive: true})
      this.updateGhostElement()
    }

    if (typeof document !== 'undefined') {
      this.interval = setInterval(() => {
        this.updateGhostElement()
      }, 10)
    }
    window.addEventListener("resize", this.updateDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScroll, {passive: true})
    clearInterval(this.interval)
    window.removeEventListener("resize", this.updateDimensions);
  }

  render() {
    this.updateGhostElement()

    //const t = getTranslateFunction('sk')

    return (
      <div
        className={'sidebar-menu'}
        style={{ visibility: 'hidden' }}
        id={'case-studies-menu-reference'}
        ref={this.listElement}
      >
        This element is only used to have sticky reference and to move ghost menu element by it.
      </div>
    )
  }
}


caseStudiesSideMenu.propTypes = {
  categoriesList: PropTypes.any,
  visibleElementChangeHandler: PropTypes.func,
  visibleElement: PropTypes.string,
  rawCategoryNames: PropTypes.bool,
  lang: PropTypes.string,
}
export default caseStudiesSideMenu
