import React, { Component } from 'react'
import Tilt from 'react-tilt'
import * as PropTypes from 'prop-types'
import './MultiLayerTilt.scss'


class MultiLayerTilt extends Component {
  /*
    render() {
      const { layers } = this.props
      return layers[0]
    }
  */

  render() {

    const { layers } = this.props
    return (
      <Tilt
        className={'multi-layer-tilt tilt'}
        style={{ transformStyle: 'preserve-3d', position: 'relative' }}
     >
        {layers.map((layer, i) => {
          const z = i * 30
          const scale = 1 - (i * 0.02)
          // const color = "#"+((1<<24)*Math.random()|0).toString(16);
          return <div className={'layer'} style={{ transform: `translateZ(${z}px) scale(${scale})`, transformSstyle: 'preserve-3d' }} key={i}>
            {layer}
          </div>
        })}
      </Tilt>
    )
  }
}

export default MultiLayerTilt

MultiLayerTilt.propTypes = {
  layers: PropTypes.arrayOf(PropTypes.element).isRequired,
}
